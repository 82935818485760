// Employee registration wizard
//
// Multi-step registration form with client-side and remote validations,
//  using GraphQL
import React from "react";
import { Wizard } from "./Wizard";
import { FormInput } from "./FormInput";
import {
  insuranceContractNumberValidator,
  lastNameValidator,
  dateOfBirthValidator,
  emailValidator
} from "./validators";
import { registerEmployee } from "./actions";

function PortalName() {
  let name = "DUK"
  if (window.location.href.includes("aupu")) {
    name = "AUPU"
  }

  return name
}

const EmployeeRegistration = () => (
  <div className="employee-registration-wizard">
    <Wizard
      initialValues={{
        insuranceContractNumber: "",
        lastName: "",
        dateOfBirth: "",
        email: ""
      }}
      handleSubmit={registerEmployee}
      // Uncomment to render a debug component for the Formik form state
      // debug={true}
    >
      <Wizard.Step title="Vertragsnummer">
        <FormInput
          component="input"
          type="text"
          name="insuranceContractNumber"
          label="Ihre Vertragsnummer"
          subtext=""
          validator={insuranceContractNumberValidator}
          placeholder="Ihre Vertragsnummer"
          successMessage="Vertragsnummer geprüft"
          autoComplete="off"
          help={
            <>
              Bitte geben Sie die Vertragsnummer Ihrer arbeitnehmerfinanzierten Versorgung ein.
              <br />
              Leerzeichen, Striche und Punkte müssen nicht mit eingegeben werden.
            </>
          }
        />
      </Wizard.Step>

      <Wizard.Step title="Versicherte Person">
        <FormInput
          component="input"
          type="text"
          name="lastName"
          label="Ihr Nachname"
          validator={lastNameValidator}
          placeholder="Nachname"
          successMessage="Nachname geprüft"
          shouldAutoFocus
        />

        <FormInput
          component="input"
          type="text"
          name="dateOfBirth"
          label="Ihr Geburtsdatum"
          validator={dateOfBirthValidator}
          placeholder="TT.MM.YYYY"
          successMessage="Geburtsdatum geprüft"
        />
      </Wizard.Step>

      <Wizard.Step title="E-Mail-Adresse">
        <FormInput
          component="input"
          // Yup handles email format validation, thus using a text input field
          type="text"
          name="email"
          label="Ihre E-Mail-Adresse"
          validator={emailValidator}
          placeholder="ihre.email@web.de"
          help={"An diese E-Mail-Adresse senden wir eine Benachrichtigung beim Vorliegen neuer Dokumente. Um die besonders strengen Datenschutz-Vorschriften einzuhalten, ist für das "+PortalName()+" Portal ausschließlich die private E-Mailadresse zu verwenden. Die Nutzung einer fremden E-Mailadresse ist nicht zulässig."}
          shouldAutoFocus
        />
      </Wizard.Step>
    </Wizard>
  </div>
);

export default EmployeeRegistration;
