// Validators
import * as Yup from "yup";
import { apolloClient } from "./graphql/apolloClient";
import {
  VALIDATE_INSURANCE_CONTRACT_NUMBER_QUERY,
  VALIDATE_INSURANCE_HOLDER_LAST_NAME_QUERY,
  VALIDATE_INSURANCE_HOLDER_DATE_OF_BIRTH_QUERY,
  VALIDATE_EMAIL_IS_NOT_YET_ASSIGNED_TO_USER_ACCOUNT_QUERY,
  VALIDATE_ONLY_EMPLOYEE_FINANCED_CONTRACTS_CAN_REGISTER_QUERY
} from "./graphql/queries";

const DATE_REGEXP = new RegExp(/\d{2}(\.|-)\d{2}(\.|-)\d{4}/);

// General wrapper for a schema and current values,
// returning a promise for async validations
const validateWith = (schema, currentValues) => {
  // Returns a validator function
  const schemaValidator = value => {
    return (
      schema
        .validate(value, {
          context: { currentValues: currentValues }
        })
        // This ensures that the error on the formik object actually is unset and vanishes
        .then(res => undefined)
        // This maps the validation error to an array with the error messages that then get displayed
        .catch(err => err.errors)
    );
  };

  return schemaValidator;
};

/////
// Insurance contract number validator
const insuranceContractNumberValidator = Yup.string()
  .required(
    "Vertragsnummer muss ausgefüllt werden und zu einer aktiven arbeitnehmerfinanzierten Versorgung gehören"
  )
  .test({
    name: "validateInsuranceContractNumber",
    // Note: Use function(value) declaration here
    test: async function (value) {
      const insuranceContractNumber = value;
      // Only query the API after a few characters have been entered
      if (insuranceContractNumber.length <= 5) {
        return this.createError({ message: 'Vertragsnummer ist nicht gültig' });
      }

      const isEmployeeFinanced = await ValidateOnlyEmployeeFinancedContractsCanRegisterRemotely({insuranceContractNumber});
      const isValid = await validateInsuranceContractNumberRemotely({insuranceContractNumber});

      if(isValid === false){
        return this.createError({ message: 'Vertragsnummer ist nicht gültig' });
      }else if(isEmployeeFinanced === false){
        return this.createError({ message: 'Bei der eingegebenen Vertragsnummer handelt es sich nicht um einen arbeitnehmerfinanzierten Vertrag' });
      }else{
        return true
      }
    }
  });

const ValidateOnlyEmployeeFinancedContractsCanRegisterRemotely = ({insuranceContractNumber}) => {
  return apolloClient
    .query({
      query: VALIDATE_ONLY_EMPLOYEE_FINANCED_CONTRACTS_CAN_REGISTER_QUERY,
      variables: {insuranceContractNumber}
    })
    .then(response => response.data.validateOnlyEmployeeFinancedContractsCanRegister);
};

const validateInsuranceContractNumberRemotely = ({insuranceContractNumber}) => {
  return apolloClient
    .query({
      query: VALIDATE_INSURANCE_CONTRACT_NUMBER_QUERY,
      variables: {insuranceContractNumber}
    })
    .then(response => response.data.validateInsuranceContractNumber);
};

/////
// Insurance holder last name validtor
const lastNameValidator = Yup.string()
  .required("Nachname muss ausgefüllt werden")
  .test({
    name: "validateInsuranceHolderLastName",
    message: "Nachname stimmt nicht mit unseren Unterlagen überein",
    // Note: Use function(value) declaration here
    test: function(value) {
      const { insuranceContractNumber } = this.options.context.currentValues;
      const lastName = value;
      return validateInsuranceHolderLastNameRemotely({ insuranceContractNumber, lastName });
    }
  });

const validateInsuranceHolderLastNameRemotely = ({ insuranceContractNumber, lastName }) => {
  if (!lastName) {
    return false;
  }

  return apolloClient
    .query({
      query: VALIDATE_INSURANCE_HOLDER_LAST_NAME_QUERY,
      variables: { insuranceContractNumber, lastName }
    })
    .then(response => response.data.validateInsuranceHolderLastName);
};

/////
// Insurance holder date of birth validator
const dateOfBirthValidator = Yup.string()
  .required("Geburtsdatum muss ausgefüllt werden")
  .matches(DATE_REGEXP, "Geburtsdatum muss ein gültiges Datum sein (Format: TT.MM.YYYY)")
  .test({
    name: "validateInsuranceHolderDateOfBirth",
    message: "Geburtsdatum stimmt nicht mit unseren Unterlagen überein",
    // Note: Use function(value) declaration here
    test: function(value) {
      const { insuranceContractNumber } = this.options.context.currentValues;
      const dateOfBirth = value;
      return validateInsuranceHolderDateOfBirthRemotely({ insuranceContractNumber, dateOfBirth });
    }
  });

const validateInsuranceHolderDateOfBirthRemotely = ({ insuranceContractNumber, dateOfBirth }) => {
  // Don't query the remote API until a valid German date is set
  if (!dateOfBirth.match(DATE_REGEXP)) {
    return false;
  }

  return apolloClient
    .query({
      query: VALIDATE_INSURANCE_HOLDER_DATE_OF_BIRTH_QUERY,
      variables: { insuranceContractNumber, dateOfBirth }
    })
    .then(response => response.data.validateInsuranceHolderDateOfBirth);
};

/////
// Email validator
const emailValidator = Yup.string()
  .required("E-Mail-Adresse muss ausgefüllt werden")
  .email("Ungültige E-Mail-Adresse")
  .test({
    name: "validateEmailIsNotYetAssignedToAUserAccount",
    message:
      "Für diese E-Mail-Adresse ist bereits ein Konto angelegt. Bitte nutzen Sie „Anmelden“ oder eine andere E-Mail-Adresse, wenn Sie sich neu registrieren wollen.",
    // Note: Use function(value) declaration here
    test: function(value) {
      const email = value;
      return validateEmailIsNotYetAssignedToAUserAccountRemotely({ email });
    }
  });

// Validates just the email format
const emailFormatValidator = Yup.string().email();

const validateEmailIsNotYetAssignedToAUserAccountRemotely = ({ email }) => {
  // Only query the API if a valid email is present
  if (!email || !emailFormatValidator.isValidSync(email)) {
    return false;
  }

  return apolloClient
    .query({
      query: VALIDATE_EMAIL_IS_NOT_YET_ASSIGNED_TO_USER_ACCOUNT_QUERY,
      variables: {
        email
      }
    })
    .then(response => response.data.validateEmailIsNotAssignedToAUserAccount);
};

export {
  validateWith,
  insuranceContractNumberValidator,
  lastNameValidator,
  dateOfBirthValidator,
  emailValidator
};
