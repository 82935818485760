// Polyfills for Internet Explorer Support
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// JS Error Tracking with Sentry
import "shared/js_error_tracking";

import Rails from "rails-ujs";

import "./employee_registration";

Rails.start();

function ready(fn) {
  if (
    document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"
  ) {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

const initApplication = () => {
  initNavigationBar();
  initFlashMessages();
};

ready(initApplication);

function initNavigationBar() {
  const navbarBurger = document.querySelector(".navbar-burger");
  const navbarMenu = document.querySelector(".navbar-menu");

  navbarBurger.addEventListener("click", () => {
    navbarBurger.classList.toggle("is-active");
    navbarMenu.classList.toggle("is-active");
  });
};

function initFlashMessages() {
  const closeButtons = document.querySelectorAll(".flash-message button.delete");

  closeButtons.forEach(closeButton => {
    closeButton.addEventListener("click", ({ target }) => {
      const flashMessage = target.parentNode;
      flashMessage.parentNode.removeChild(flashMessage);
    });
  });
};
