import React from "react";
import { Field, FormikConsumer, ErrorMessage } from "formik";
import { validateWith } from "./validators";

export const FormInput = ({
  component,
  name,
  label,
  successMessage,
  className,
  help,
  selectOptions,
  validator,
  shouldAutoFocus,
  ...props
}) => (
  <FormikConsumer>
    {({ values, initialValues, errors, touched }) => {
      const hasInitialValue = values[name] === initialValues[name];
      const hasChangedValue = !hasInitialValue;

      const isTouched = touched[name];
      const isError = isTouched && errors[name];
      const isSuccess = isTouched && !errors[name];

      const showInputSuccess = !errors[name] && hasChangedValue;
      const inputShouldAutoFocus = shouldAutoFocus && hasInitialValue;

      return (
        <div className="field">
          <label htmlFor={name} className="label">
            {label}
          </label>

          {help && (
            <p className="help" style={{ marginTop: "-0.3rem", marginBottom: "0.6rem" }}>
              {help}
            </p>
          )}

          {component === "select" && (
            <div className="control">
              <span className={`select is-medium ${className}`}>
                <Field component={component} name={name} id={name}>
                  {selectOptions}
                </Field>
              </span>
            </div>
          )}

          {component !== "select" && (
            <div className="control has-icons-right">
              <Field
                component={component}
                name={name}
                id={name}
                validate={validateWith(validator, values)}
                className={`input is-medium ${className} ${isError && "is-danger"} ${isSuccess &&
                  "is-success"}`}
                autoFocus={inputShouldAutoFocus}
                {...props}
              />

              {showInputSuccess && (
                <span className="icon is-right has-text-success">
                  <i className="fas fa-check" />
                </span>
              )}
            </div>
          )}

          <ErrorMessage name={name}>
            {message => (
              <p className="help is-danger">
                <i className="fas fa-exclamation-triangle" />
                {message}
              </p>
            )}
          </ErrorMessage>

          {successMessage && showInputSuccess && (
            <p className="help is-success">
              <i className="fas fa-check" />
              {successMessage}. Vielen Dank!
            </p>
          )}
        </div>
      );
    }}
  </FormikConsumer>
);
