import React from "react";
import { apolloClient } from "./graphql/apolloClient";
import gql from "graphql-tag";

const VALIDATE_INSURANCE_HOLDER_ALREADY_REGISTERED_QUERY = gql`
  query ValidateInsuranceHolderAlreadyRegistered(
    $insuranceContractNumber: String!
    $lastName: String!
    $dateOfBirth: String!
  ) {
    validateInsuranceHolderAlreadyRegistered(
      insuranceContractNumber: $insuranceContractNumber
      lastName: $lastName
      dateOfBirth: $dateOfBirth
    )
  }
`;

export default class InsuranceHolderAlreadyRegisteredAlertBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insuranceHolderIsAlreadyRegistered: false
    };
  }

  componentDidMount() {
    apolloClient
      .query({
        query: VALIDATE_INSURANCE_HOLDER_ALREADY_REGISTERED_QUERY,
        variables: {
          insuranceContractNumber: this.props.insuranceContractNumber,
          lastName: this.props.lastName,
          dateOfBirth: this.props.dateOfBirth
        }
      })
      .then(response =>
        this.setState({
          insuranceHolderIsAlreadyRegistered: response.data.validateInsuranceHolderAlreadyRegistered
        })
      );
  }

  render() {
    const { insuranceHolderIsAlreadyRegistered } = this.state;

    if (!insuranceHolderIsAlreadyRegistered) {
      return null;
    }

    return (
      <div className="notification is-danger">
        Für die eingegebene Vertragsnummer existiert bereits ein Nutzerkonto. Sie können sich{" "}
        <a href="/anmelden">hier direkt in ihr Nutzerkonto einloggen</a>.
        <br />
        Bitte registrieren Sie sich nur dann erneut wenn Sie keinen Zugang mehr zu ihrer bisherigen
        E-Mail-Adresse haben.
      </div>
    );
  }
}
