// GraphQL Queries
import gql from "graphql-tag";

const VALIDATE_EMAIL_IS_NOT_YET_ASSIGNED_TO_USER_ACCOUNT_QUERY = gql`
  query ValidateEmailIsNotAssignedToAUserAccount($email: String!) {
    validateEmailIsNotAssignedToAUserAccount(email: $email)
  }
`;

const VALIDATE_INSURANCE_CONTRACT_NUMBER_QUERY = gql`
  query ValidateInsuranceContractNumber($insuranceContractNumber: String!) {
    validateInsuranceContractNumber(insuranceContractNumber: $insuranceContractNumber)
  }
`;

const VALIDATE_INSURANCE_HOLDER_LAST_NAME_QUERY = gql`
  query ValidateInsuranceHolderLastName($insuranceContractNumber: String!, $lastName: String!) {
    validateInsuranceHolderLastName(
      insuranceContractNumber: $insuranceContractNumber
      lastName: $lastName
    )
  }
`;

const VALIDATE_INSURANCE_HOLDER_DATE_OF_BIRTH_QUERY = gql`
  query ValidateInsuranceHolderDateOfBirth(
    $insuranceContractNumber: String!
    $dateOfBirth: String!
  ) {
    validateInsuranceHolderDateOfBirth(
      insuranceContractNumber: $insuranceContractNumber
      dateOfBirth: $dateOfBirth
    )
  }
`;

const VALIDATE_ONLY_EMPLOYEE_FINANCED_CONTRACTS_CAN_REGISTER_QUERY  = gql`
  query ValidateOnlyEmployeeFinancedContractsCanRegister($insuranceContractNumber: String!) {
    validateOnlyEmployeeFinancedContractsCanRegister(insuranceContractNumber: $insuranceContractNumber)
  }
`;

export {
  VALIDATE_EMAIL_IS_NOT_YET_ASSIGNED_TO_USER_ACCOUNT_QUERY,
  VALIDATE_INSURANCE_CONTRACT_NUMBER_QUERY,
  VALIDATE_INSURANCE_HOLDER_LAST_NAME_QUERY,
  VALIDATE_INSURANCE_HOLDER_DATE_OF_BIRTH_QUERY,
  VALIDATE_ONLY_EMPLOYEE_FINANCED_CONTRACTS_CAN_REGISTER_QUERY
};
