import React from "react";
import { createRoot } from "react-dom/client";
import EmployeeRegistration from "EmployeeRegistration";

document.addEventListener("DOMContentLoaded", () => {
  const mountNode = document.getElementById("employee-registration-root");
  if (mountNode) {
    const root = createRoot(mountNode); // Create a root
    root.render(<EmployeeRegistration />); // Render the component
  }
});