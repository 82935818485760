// Shared GraphQL Client with config for our GraphQL API
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, concat } from "apollo-link";

const httpLink = new HttpLink({ uri: "/graphql" });

const authMiddleware = new ApolloLink((operation, forward) => {
  const csrfMetaTag = document.querySelector("meta[name=csrf-token]");
  const csrfToken = csrfMetaTag && csrfMetaTag.getAttribute("content");
  if (csrfToken) {
    operation.setContext({
      headers: {
        "X-CSRF-Token": csrfToken
      }
    });
  }
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

export { apolloClient };
