import React from "react";
import { Formik, Form } from "formik";
import { Debug } from "./FormikDebug";
import InsuranceHolderAlreadyRegisteredAlertBox from "./InsuranceHolderAlreadyRegisteredAlertBox";

const RegistrationSuccess = () => (
  <div className="employee-registration-success has-text-centered">
    <div
      className="title is-3 has-text-grey"
      style={{ paddingTop: "1rem", marginBottom: "1.25rem" }}
    >
      <i className="far fa-envelope-open" /> E-Mail-Adresse bestätigen
    </div>
    <hr />
    <div className="box-subtitle">
      Wir haben eine <b>E-Mail mit einem Bestätigungslink</b> an Sie gesendet. Bitte klicken Sie den
      Link in der E-Mail oder kopieren Sie ihn in die Addressleiste Ihres Browsers.
    </div>
  </div>
);

export class Wizard extends React.Component {
  static Step = ({ children }) => <div className="wizard-step">{children}</div>;
  static Header = ({ children }) => <div className="wizard-header">{children}</div>;
  static HeaderItem = ({ title, step, currentStep }) => (
    <span
      className={`wizard-header-step ${step == currentStep && "is-active"} ${step < currentStep &&
        "is-done"}`}
    >
      <span className="step-number">{step + 1}</span>
      <span className="step-title">{title}</span>
    </span>
  );
  static Body = ({ children }) => <div className="wizard-body">{children}</div>;
  static Actions = ({ children }) => <div className="wizard-actions">{children}</div>;
  state = {
    step: 0,
    values: this.props.initialValues,
    registrationCompleted: false
  };

  nextStep = values => {
    this.setState(prevState => ({
      step: Math.min(prevState.step + 1, this.props.children.length - 1),
      values
    }));
  };

  prevStep = () => {
    this.setState(prevState => ({
      step: Math.max(prevState.step - 1, 0)
    }));
  };

  handleSubmit = (values, actions) => {
    const { children } = this.props;
    const { step } = this.state;
    const activeStep = React.Children.toArray(children)[step];
    const isFinalStep = step == React.Children.count(children) - 1;
    if (isFinalStep) {
      this.props
        .handleSubmit(values)
        .then(response => {
          this.setState(prevState => ({
            registrationCompleted: true
          }));
          actions.setSubmitting(false);
        })
        .catch(err => console.log(err));
    } else {
      this.nextStep(values);
      actions.setTouched({});
      actions.setErrors({});
      actions.setSubmitting(false);
    }
  };

  render() {
    const { children } = this.props;
    const { step, values } = this.state;
    const activeStep = React.Children.toArray(children)[step];
    const isFirstStep = step == 0;
    const isFinalStep = step == React.Children.count(children) - 1;
    const inputsInActiveStep = React.Children.toArray(activeStep.props.children).map(
      child => child.props.name
    );
    const { registrationCompleted } = this.state;

    return (
      <Formik
        initialValues={values}
        onSubmit={this.handleSubmit}
        render={({ errors, dirty, isValid, isValidating, isSubmitting }) => (
          <>
            {!registrationCompleted && (
              <Form noValidate className="employee-registration-form">
                <Wizard.Header>
                  {React.Children.map(children, (child, index) => {
                    return (
                      <Wizard.HeaderItem
                        title={child.props.title}
                        step={index}
                        currentStep={step}
                      />
                    );
                  })}
                </Wizard.Header>

                {isFinalStep && (
                  <InsuranceHolderAlreadyRegisteredAlertBox
                    insuranceContractNumber={values.insuranceContractNumber}
                    lastName={values.lastName}
                    dateOfBirth={values.dateOfBirth}
                  />
                )}

                <Wizard.Body>{activeStep}</Wizard.Body>

                <Wizard.Actions>
                  {!isFirstStep && (
                    <BackButton onClick={this.prevStep} disabled={isFirstStep || isSubmitting} />
                  )}
                  <SubmitButton
                    disabled={
                      !dirty ||
                      isValidating ||
                      isSubmitting ||
                      (!isValid &&
                        Object.keys(errors).some(inputName =>
                          inputsInActiveStep.includes(inputName)
                        ))
                    }
                  />
                </Wizard.Actions>

                {this.props.debug && <Debug />}
              </Form>
            )}

            {registrationCompleted && <RegistrationSuccess />}
          </>
        )}
      />
    );
  }
}

const BackButton = ({ onClick, disabled }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className="button is-outlined is-rounded is-back-button"
  >
    &larr; Zurück
  </button>
);

const SubmitButton = ({ disabled }) => (
  <button
    type="submit"
    className="button is-success is-medium is-rounded is-submit-buttom"
    disabled={disabled}
  >
    Weiter &rarr;
  </button>
);
