// GraphQL Mutations
import gql from "graphql-tag";

const REGISTER_EMPLOYEE_MUTATION = gql`
  mutation registerEmployee(
    $insuranceContractNumber: String!
    $lastName: String!
    $dateOfBirth: String!
    $email: String!
  ) {
    registerEmployee(
      insuranceContractNumber: $insuranceContractNumber
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      email: $email
    ) {
      result
    }
  }
`;

export { REGISTER_EMPLOYEE_MUTATION };
